import { atom } from 'jotai';
import { Purchase } from '../Models/Purchase';
import { PaymentRequest, PaymentRequestPaymentMethodEvent, Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { InkyApiV2 } from '../API/InkyApiV2';
import { FetchCartAtom, PersistentCartIdAtom } from '../store/CartStore';
import { v4 as uuid } from 'uuid';

export const couponAtom = atom('');
export const showQRCodeAtom = atom('');
export const startPollingAtom = atom(false);
export const startUpdatePollingAtom = atom(false);


export const PurchaseRequestEndpointResponse = atom<any>('');


//region CartId
const PersistentCartIdKey = 'CartId';
export const CartIdAtom = atom(
    (get) => get(PersistentCartIdAtom),
    (get, set, cartId: string) => {
        console.log('[CartID] Setting CartId to ' + cartId);
        localStorage.setItem(PersistentCartIdKey, cartId);
        // Set the Api before anything else, since other atoms may trigger API-calls
        InkyApiV2.shared().CartId = cartId;
        // Set other atoms.
        set(PersistentCartIdAtom, cartId);
        set(FetchCartAtom);
    },
);

CartIdAtom.onMount = (set): void => {
    console.log('[CartID] Initiated.');
    // Set the CartID to be the stored value, if that does not exist, create a new one.
    set(localStorage.getItem(PersistentCartIdKey) || uuid());
};

//endregion


export enum PaymentStatus {
    'Waiting',
    'Error',
    'Success',
}

export const paymentStatusAtom = atom<PaymentStatus>(PaymentStatus.Waiting);
export const paymentActionAtom = atom<string>('');
export const UpdateModalAtom = atom<boolean>(false);


export const StripeCardElementAtom = atom<StripeCardNumberElement | false>(false);

export const StripePaymentRequestAtom = atom<PaymentRequest | false>(false);

export const StripeObjectAtom = atom<Stripe | false>(false);

export enum StripeCardErrorMessage {
    IsValid,
    StripeCardInvalidCardNumber,
    StripeCardInvalidExpirationDate,
    StripeCardInvalidCCVNumber
}

export enum StripeProcessingErrorMessage {
    NoError,
    UnknownError,
    ApiConnectionError,
    ApiError,
    ApiAuthError,
    CardError,
    IdempotencyError,
    InvalidRequestError,
    RateLimitError,
    ValidationError
}

export enum PurchaseValidationError {
    NoError,
    UnknownError,
    TaxError
}


// This is the Primary error-handler. Sub-handlers should set and reset this.

// Stripe Card-info valudation error-handler
export const StripeCardInfoErrorHandlerAtom = atom<StripeCardErrorMessage>(StripeCardErrorMessage.IsValid);
export const StripePaymentProcessingErrorHandlerAtom = atom<StripeProcessingErrorMessage>(StripeProcessingErrorMessage.NoError);

export const PurchaseValidationErrorHandlerAtom = atom<PurchaseValidationError>(PurchaseValidationError.NoError);
export const ShowCheckoutErrorMessages = atom<boolean>(false);


// Old errorhandling beneath here!
interface InitiatePurchaseProps {
    pr: Purchase;
    webpayPaymentEvent?: PaymentRequestPaymentMethodEvent;
}