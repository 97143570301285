import React, { ReactElement, PropsWithChildren } from 'react';
import {
    LinkProps as OriginalLinkProps,
    NavLinkProps as OriginalNavLinkProps,
    Link as OriginalLink,
    NavLink as OriginalNavLink,
} from 'react-router-dom';

export type LinkProps<S> = OriginalLinkProps<S>;
export type NavLinkProps<S> = OriginalNavLinkProps<S>;

export function ensureTrailingSlash(url: string): string {
    // do not change absolute URLs
    if (url.startsWith('https://') || url.startsWith('http://')) {
        return url;
    }
    let base: string;
    try {
        base = window.location.origin;
    } catch(e) {
        console.error(e);
        // fallback to localhost for unit tests
        base = 'http://localhost:3000';
    }
    try {
        const urlObj = new URL(url, base);
        // do not change URLS with extensions (e.g. foo.pdf)
        if (/\.\w+$/.test(urlObj.pathname)) {
            return url;
        }
        // nothing to add
        if (urlObj.pathname.endsWith('/')) {
            return url;
        }
        // add trailing slash
        urlObj.pathname = urlObj.pathname + '/';
        // remove base
        let out = `${urlObj}`.substring(base.length);
        if (out.startsWith('/') && !url.startsWith('/')) {
            out = out.substring(1);
        }
        return out;
    }  catch (e) {
        console.error(e);
    }
    return url;
}

let Link = OriginalLink;
/////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////////////////////
////////////////
/////////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////
//////////////////////////
/////////////////
//////////
/////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////
/////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////
///////////////////////////////////////////////
 
//////////
export { Link };

let NavLink = OriginalNavLink;
/////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////////////////////
////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////
//////////////////////////
/////////////////
//////////
/////
//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////
/////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////
//////////////////////////////////////////////////
 
//////////
export { NavLink };

interface HashLinkProps {
    targetId: string;
}

export function HashLink({ targetId, children }: PropsWithChildren<HashLinkProps>): ReactElement {
    return <Link to={{ hash: '#' + targetId }} onClick={(e): void => {
        e.preventDefault();
        e.stopPropagation();
        const target = document.getElementById(targetId);
        if (target) {
            target.scrollIntoView({block: "center", inline: "nearest", behavior: 'smooth'});
        }
    }}>{children}</Link>;
}
