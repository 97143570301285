import { atom } from 'jotai';
import axios from 'axios';
import apiClient from '../API/InkyAPI';
import { DiscoverItem } from '../Models/DiscoverItem';
import { PriceTypeFlagEnum } from '../Models/BrowseData';
import { SortEnums } from '../InkyPen/BrowseComponent/browseConstant';

export interface BookshelfAtomType {
    sortOrder: SortEnums; // The Sort-order
    includeSeries: boolean;
    paginationPageNumber: number; // Starts at 0!!
    itemsPerPage: number;
    searchQuery: string;
}

let source;

export const BookshelfArrayAtom = atom<DiscoverItem[]>([]);

export const BookshelfCountAtom = atom<number>(0);
export const FetchBookshelfAtom = atom((get) => {
        return get(BookshelfArrayAtom);
    },
    async (get, set, data: BookshelfAtomType) => {
        console.debug('Browsefilter is', data);
        if (!data) {
            return;
        }

        if (source) {
            source?.cancel();
        }

        source = axios.CancelToken.source();

        try {
            const res = await apiClient.getBookshelf(
                parseInt(data.sortOrder),
                data.includeSeries,
                data.searchQuery,
                source,
                (data.itemsPerPage ?? 24) * (data.paginationPageNumber ?? 0), data.itemsPerPage,
                -1,
            );
            // mutate = res.response;
            set(BookshelfArrayAtom, res.response);
            set(BookshelfCountAtom, res.status.fullCount);
        } catch (e) {
            console.error('[DiscoverStore] Could not fetch Browse-data: ' + e.message, e);
            throw e;
        }
    },
);
