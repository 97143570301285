export class DashHtmlComponent {
    private static _reactKey = 0;
    readonly reactKey: string;

    original?: DashHtmlComponent;
    id?: number;
    name?: string;
    style?: string;
    content?: string;

    constructor() {
        this.reactKey = `${DashHtmlComponent._reactKey++}`;
    }

    static create(options: {
        id?: number;
        name?: string;
        style?: string;
        content?: string;
    }): DashHtmlComponent {
        const original = Object.assign(new DashHtmlComponent(), options);
        return Object.assign(new DashHtmlComponent(), original, { original });
    }

    static fromApi(dto: DashHtmlComponentDTO): DashHtmlComponent {
        return DashHtmlComponent.create(dto);
    }

    toApi(): DashHtmlComponentDTO {
        return { id: this.id, name: this.name, style: this.style, content: this.content };
    }

    hasChanged(): boolean {
        return !!this.original && !this.equals(this.original);
    }

    equals(other: DashHtmlComponent): boolean {
        return this.id === other.id
            && this.name === other.name
            && this.style === other.style
            && this.content === other.content;
    }

    revert(): DashHtmlComponent {
        const original = this.original;
        return Object.assign(new DashHtmlComponent(), original, { original });
    }

    merge(options: {
        id?: number;
        name?: string;
        style?: string;
        content?: string;
    }): DashHtmlComponent {
        return Object.assign(new DashHtmlComponent(), this, options);
    }

    clone(): DashHtmlComponent {
        return Object.assign(new DashHtmlComponent(), this);
    }
}

export interface DashHtmlComponentDTO {
    id?: number;
    name?: string;
    style?: string;
    content?: string;
}
