import React, { useEffect } from 'react';
import './3DSecure.scss';
import { QRCodeSVG } from 'qrcode.react';
import { useAtom } from 'jotai';
import { showQRCodeAtom, startPollingAtom, startUpdatePollingAtom } from '../stores';
import { useSetAtom } from 'jotai';

const Secure3D = () => {
    const [showQRCode, setShowErrors] = useAtom(showQRCodeAtom);
    const setStartPolling = useSetAtom(startPollingAtom);
    const setStartUpdatePollingAtom = useSetAtom(startUpdatePollingAtom);

    useEffect(() => {
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const update = urlParams.get('update');

        console.log('StartPolling setting');
        if (!update) {
            setStartPolling(true);
        } else {
            setStartUpdatePollingAtom(true);
        }
    }, []);


    const url = new URL(showQRCode);
    const pathname = url.pathname;
    const segments = pathname.split('/');
    let code;

    if (segments.length >= 2) {
        code = segments[segments.length - 1];
    }

    return (
        <div className='secure-container'>
            <div className='secure-left-wrapper'>
                <h3 className='secure-second-title'>
                    Finish setting up your subscription
                </h3>

                <span className='secure-text'>
                    Scan the QR code on the right with your mobile device...
                </span>

                <div className='secure-divider-container'>
                    <div className={'secure-pink-OR'}>
                        OR
                    </div>

                    <div className='secure-divider'>

                    </div>
                </div>

                <span className='secure-text secure-text-visit'>
                    Visit https://inky-pen.com/subscribe and enter this 8 digit code
                </span>

                <span className='secure-digit-code'>
                    {code}
                </span>
            </div>

            <div className='secure-right-wrapper'>
                <QRCodeSVG value={showQRCode} className='secure-qr-code' />
            </div>
        </div>
    );
};

export default React.memo(Secure3D);
