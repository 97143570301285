import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import {
    paymentActionAtom,
    PaymentStatus,
    paymentStatusAtom,
    PurchaseRequestEndpointResponse,
    ShowCheckoutErrorMessages,
    showQRCodeAtom,
    StripeCardElementAtom,
    StripeCardErrorMessage,
    StripeCardInfoErrorHandlerAtom,
    StripeObjectAtom,
    StripePaymentProcessingErrorHandlerAtom,
    StripeProcessingErrorMessage,
} from '../stores';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import './SwitchPaymentProcessing.scss';
import clsx from 'clsx';
import { PurchaseWithCardNintendo } from '../endpoints/endpoints';

/*
    The function to process the card-data is in the [StripeConfirmPaymentAtom] and is currently used in [KodanshaCheckout.tsx]
 */
export function SwitchCardProcessingForm({
                                             showUpdatePayment = false,
                                         }): JSX.Element {
    const [, setStripeCardElement] = useAtom(StripeCardElementAtom);
    const [, setStripeObject] = useAtom(StripeObjectAtom);

    const [stripeCardInfoErrorHandler, setStripeCardInfoErrorHandler] = useAtom(
        StripeCardInfoErrorHandlerAtom,
    );
    const [stripePaymentProcessingError] = useAtom(
        StripePaymentProcessingErrorHandlerAtom,
    );
    const [showErrors, setShowErrors] = useAtom(ShowCheckoutErrorMessages);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const setPaymentStatus = useSetAtom(paymentStatusAtom);
    const [_, setPaymentAction] = useAtom(paymentActionAtom);
    const setShowQRCode = useSetAtom(showQRCodeAtom);
    const [
        purchaseRequestEndpointResponse,
        setPurchaseRequestEndpointResponse,
    ] = useAtom(PurchaseRequestEndpointResponse);

    const stripe = useStripe();
    const elements = useElements();

    console.log(
        'result stripeCardInfoErrorHandler',
        stripeCardInfoErrorHandler,
    );

    const [cardNumberIsValid, setCardNumberIsValid] = useState(false);
    const [cardExpiryIsValid, setCardExpiryIsValid] = useState(false);
    const [cardCvcIsValid, setCardCvcIsValid] = useState(false);
    const cardNumberElement = elements?.getElement('cardNumber');

    useEffect(() => {
        if (!cardNumberIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidCardNumber,
            );
            return;
        }

        if (!cardExpiryIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidExpirationDate,
            );
            return;
        }

        if (!cardCvcIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidCCVNumber,
            );
            return;
        }

        if (cardNumberIsValid && cardExpiryIsValid && cardCvcIsValid) {
            setStripeCardInfoErrorHandler(StripeCardErrorMessage.IsValid);
        }
    }, [cardNumberIsValid, cardExpiryIsValid, cardCvcIsValid]);

    const appearance = {
        theme: 'stripe',

        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
            // See all possible variables below
        },
    };

    function updateCardInfo(event: StripeElementChangeEvent): void {
        if (event.elementType == 'cardNumber') {
            console.log('Change');
            setCardNumberIsValid(event.complete);
        }

        if (event.elementType == 'cardExpiry') {
            setCardExpiryIsValid(event.complete);
        }

        if (event.elementType == 'cardCvc') {
            setCardCvcIsValid(event.complete);
        }

        setStripeCardElement(cardNumberElement);
        setStripeObject(stripe);
    }

    const style = {
        base: {
            fontFamily:
                'SourceSans3-SemiBold, SourceSans3-Regular-Medium, sans-serif',
            fontSize: '17px',
        },
    };

    const cardNumberClasses = clsx({
        'card-details-input': true,
        cardNumberElement: true,
        inputError:
            (!cardNumberIsValid ||
                stripePaymentProcessingError ===
                StripeProcessingErrorMessage.CardError) &&
            showErrors,
    });

    const cardExpiryClasses = clsx({
        'card-details-input': true,
        cardExpiryElement: true,
        inputError: !cardExpiryIsValid && showErrors,
    });

    const cardCvcClasses = clsx({
        'card-details-input': true,
        cardCvcElement: true,
        inputError: !cardCvcIsValid && showErrors,
    });

    async function submitPayment(e) {
        e.preventDefault();

        if (loading || (!cardNumberIsValid && !cardExpiryIsValid && !cardCvcIsValid)) {
            return;
        }

        setLoading(true);
        setError('');

        stripe
            ?.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
            })
            .then(async function(result) {
                if (result.error) {
                    console.error('result error:', result.error.message);
                    setLoading(false);
                    setError(result?.error?.message ?? '');
                    // setPaymentStatus(PaymentStatus.Error);
                } else {
                    // Payment method created successfully
                    const paymentMethod = result.paymentMethod;
                    console.log('paymentMethod 1', result);
                    console.log(
                        'paymentMethod 2',
                        purchaseRequestEndpointResponse,
                    );

                    await PurchaseWithCardNintendo(
                        { ...purchaseRequestEndpointResponse },
                        paymentMethod.id,
                    )
                        .then(async (result: any) => {
                            console.log('ppp', result);
                            const protocol = window.location.protocol;
                            const host = window.location.host;

                            if (result.status.type === 'GeneralError') {
                                setError(result.status.message);
                                return;
                            }

                            if (result.status.type === 'BadRequest') {
                                setError(result.status.message);
                                return;
                            }

                            if (result.status.type === 'Success' && result.response.requiresAction === false) {
                                setPaymentStatus(PaymentStatus.Success);
                                setPaymentAction('InitialSubscription');
                                return;
                            }

                            const successURL = `${protocol}//${host}`;
                            console.log('confirmCardPayment result', result);

                            setShowQRCode(`${process.env.REACT_APP_BASE_URL}/url/${result.response.shortUrlCode}`);
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error?.status?.message) {
                                setError(error.status.message);
                                return;
                            }
                            // setError('Try again later.');
                        }).finally(() => {
                            setLoading(false);
                        });
                    console.log('result success:', result);
                }
            })
            .finally(() => {
                setLoading(false);
                console.log('paymentMethod Final');
            });
    }

    console.log('stripePaymentProcessingError', error);

    return (
        <>
            {loading ? <div className='update-card-lock-click' onClick={e => e.stopPropagation()} /> : null}
            <div className={'payment-details-container'}>
                <div className='payment-details-wrapper'>
                    <div className='payment-details-column-wrapper'>
                        {/*<input type='text' placeholder={'Card holder name'} className='payment-details-input' />*/}

                        <div className='card-details-wrapper'>
                            <CardNumberElement
                                className={cardNumberClasses}
                                options={{
                                    placeholder: 'Card number',
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />
                            {!cardNumberIsValid && showErrors && (
                                <span className={'inputErrorLabel'}>
                                Card-number is invalid
                            </span>
                            )}
                            {stripePaymentProcessingError ===
                                StripeProcessingErrorMessage.CardError &&
                                showErrors && (
                                    <span className={'inputErrorLabel'}>
                                    This card has been declined
                                </span>
                                )}

                            <CardExpiryElement
                                className={cardExpiryClasses}
                                options={{
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />
                            {!cardExpiryIsValid && showErrors && (
                                <span className={'inputErrorLabel'}>
                                Expiration-Date on card is invalid
                            </span>
                            )}

                            <CardCvcElement
                                className={cardCvcClasses}
                                options={{
                                    placeholder: 'CVC',
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />
                        </div>

                        {error && (
                            <span className='nintendo-error'>
                                {error}
                            </span>
                        )}

                        {/*<span className='automatic-subscription-text'>*/}
                        {/*    This service is automatically renewed at the end of every subscription period.*/}
                        {/*    <br />*/}
                        {/*    By subscribing you agree to our <Link to={''}>Terms of Service.</Link>*/}
                        {/*</span>*/}

                        <button
                            onClick={submitPayment}
                            className={clsx(
                                'subscribe-button',
                                (loading || (!cardNumberIsValid || !cardExpiryIsValid || !cardCvcIsValid)) && 'subscribe-button-loading',
                            )}
                        >
                            {loading ? 'Processing' : 'Subscribe'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
