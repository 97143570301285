import { DEFAULT_API_VERSION } from '../../API/InkyAPI';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { SubscriptionTypeBasic } from '../constants';

const apiBaseUrl = process.env.REACT_APP_BASE_URL;

function post<T>(
    url: string,
    payload: Record<string, unknown>,
    cancelTokenSource?: CancelTokenSource,
    apiVersion?: string,
): Promise<AxiosResponse<T>> {
    let token = localStorage.getItem('AccessToken'); // authClient.getAccessToken();

    if (token === undefined || token === null) {
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        token = urlParams.get('token');
        console.log('token is', token);
        localStorage.setItem('AccessToken', token);

        const newUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname;

        window.history.replaceState({}, document.title, newUrl);
    }

    const fullUrl = new URL(url, apiBaseUrl);
    fullUrl.searchParams.append(
        'api-version',
        apiVersion ?? DEFAULT_API_VERSION,
    );

    return axios.post<T>(fullUrl.href, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
    });
}

type SuccessResponse = {
    response: string;
    status: {
        type: 'Success';
    };
};

type ErrorResponse = {
    status: {
        type: 'GeneralError';
        message: string;
    };
};

const exampleApiResponse: ApiResponse = {
    response: 'Coupon applied',
    status: {
        type: 'Success',
    },
};

type ApiResponse = SuccessResponse | ErrorResponse;

export async function claimCode(code: string) {
    const response = await post<ApiResponse>('/coupons/claim', { code }, null);
    return response;
}

export async function AddToCart(
    CartId: string,
    patchData: SubscriptionTypeBasic,
) {
    // const patchData = {
    //     productId: 205946,
    //     variantId: -3,
    //     amount: 1,
    // };

    return axios
        .patch(apiBaseUrl + '/cart/' + CartId, patchData)
        .then((response) => {
            console.log('Purchase cart update successful:', response.data);
            return response.data.response.guestId;
        })
        .catch((error) => {
            console.error('Purchase Patch failed:', error.response.data);
        });
}

export async function PollingStatus() {
    console.log('PollingStatus starts');
    const token = localStorage.getItem('AccessToken');

    return axios.get(apiBaseUrl + '/order/subscription-approved', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then((response: any) => {
        console.log('PollingStatus successful:', response.data);
        return response.data;
    })
        .catch((error) => {
            console.error('PollingStatus failed:', error.response.data);
        }).finally(() => {
            console.log('PollingStatus ends');
        });
}

export async function getAccountInfo(token: string) {
    console.log('Account Fetch');

    return axios.get(apiBaseUrl + '/account', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then((response: any) => {
        return response.data;
    }).catch((error) => {
        setTimeout(() => {
            getAccountInfo(token);
        }, 5000);
    }).finally(() => {
        console.log('Account Fetch ends');
    });
}

export async function UpdatePollingStatus() {
    console.log('Update PollingStatus starts');
    const token = localStorage.getItem('AccessToken');

    return axios.get(apiBaseUrl + '/user/currentPaymentMethodStatus', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then((response: any) => {
        console.log('Update  PollingStatus successful:', response);
        return response;
    })
        .catch((error) => {
            console.error('Update  PollingStatus failed:', error);
        }).finally(() => {
            console.log('Update  PollingStatus ends');
        });
}

export async function PurchaseRequest(guestId: string) {
    console.log('here PurchaseRequest');

    const cart = {
        guestId: guestId,
        splitOrder: false,
    };

    return post(apiBaseUrl + '/purchase-request/', cart)
        .then((response: any) => {
            console.log('Purchase Patch successful:', response.data);
            return response.data.response;
        })
        .catch((error) => {
            console.error('Purchase Patch failed:', error.response.data);
        });
}

export function PurchaseWithCard(all: any) {
    return post(apiBaseUrl + '/order/purchase', {
        ...all,
        paymentMethod: 'Card',
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Purchase Error purchasing:', error.response.data);
        });
}

export function PurchaseWithCardNintendo(all: any, paymentId: string) {
    return post(apiBaseUrl + '/order/purchase?platform=switch', {
        ...all,
        StripeNonce: paymentId,
        paymentMethod: 'Card',
    })
        .then((response) => {
            console.log('Purchase Success purchasing:');
            return response.data;
        })
        .catch((error) => {
            console.error('Purchase Error purchasing:', error.response.data);
            return error.response.data;
        });
}

export async function CheckUnPaidInvoice() {
    console.log('CheckUnPaidInvoice starts');
    const token = localStorage.getItem('AccessToken');

    return axios.get(apiBaseUrl + '/user/hasunpaidinvoice', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then((response: any) => {
        if (response.status === 200) {
            console.log('CheckUnPaidInvoice successful:', response);
            return response.data;
        }
    })
        .catch((error) => {
            console.error('CheckUnPaidInvoice failed:', error.response.data);
        }).finally(() => {
            console.log('CheckUnPaidInvoice ends');
        });
}

export function SubmitPaymentMethodUpdate(all: any, paymentId: string) {
    return post(apiBaseUrl + '/user/updatepaymentauthorized', {
        ...all,
        StripeNonce: paymentId,
        paymentMethod: 'Card',
    })
        .then((response) => {
            console.log('Purchase Success purchasing:');
            return response.data;
        })
        .catch((error) => {
            console.error('Purchase Error purchasing:', error.response.data);
            throw new Error(error.response.data);
        });
}

export function removeFromCard(CartId: string, productId: any) {
    const patchData = {
        productId: productId,
        VariantId: -3,
        Amount: -1,
        RemoveAll: true,
    };

    return axios
        .patch(apiBaseUrl + '/cart/' + CartId, patchData)
        .then((response) => {
            console.log('Purchase cart update successful:', response.data);
            return response.data.status.type === 'Success';
        });
}
