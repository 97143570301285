import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import {
    paymentActionAtom,
    PurchaseRequestEndpointResponse,
    ShowCheckoutErrorMessages, showQRCodeAtom,
    StripeCardElementAtom,
    StripeCardErrorMessage,
    StripeCardInfoErrorHandlerAtom,
    StripeObjectAtom,
    StripePaymentProcessingErrorHandlerAtom,
    StripeProcessingErrorMessage,
} from '../stores';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import './SwitchPaymentProcessing.scss';
import clsx from 'clsx';
import {
    CheckUnPaidInvoice,
    SubmitPaymentMethodUpdate,
} from '../endpoints/endpoints';

export function UpdateCard(): JSX.Element {
    const [, setStripeCardElement] = useAtom(StripeCardElementAtom);
    const [, setStripeObject] = useAtom(StripeObjectAtom);
    const setShowQRCode = useSetAtom(showQRCodeAtom);

    const [stripeCardInfoErrorHandler, setStripeCardInfoErrorHandler] = useAtom(
        StripeCardInfoErrorHandlerAtom,
    );
    const [stripePaymentProcessingError] = useAtom(
        StripePaymentProcessingErrorHandlerAtom,
    );
    const [showErrors, setShowErrors] = useAtom(ShowCheckoutErrorMessages);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [_, setPaymentAction] = useAtom(paymentActionAtom);
    const [purchaseRequestEndpointResponse,] = useAtom(PurchaseRequestEndpointResponse);

    const stripe = useStripe();
    const elements = useElements();

    console.log(
        'result stripeCardInfoErrorHandler',
        stripeCardInfoErrorHandler,
    );

    const [cardNumberIsValid, setCardNumberIsValid] = useState(false);
    const [cardExpiryIsValid, setCardExpiryIsValid] = useState(false);
    const [cardCvcIsValid, setCardCvcIsValid] = useState(false);
    const cardNumberElement = elements?.getElement('cardNumber');

    useEffect(() => {
        CheckUnPaidInvoice().then((result) => {
            console.log('CheckUnPaidInvoice component', result);
        });
    }, []);

    useEffect(() => {
        if (!cardNumberIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidCardNumber,
            );
            return;
        }

        if (!cardExpiryIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidExpirationDate,
            );
            return;
        }

        if (!cardCvcIsValid) {
            setStripeCardInfoErrorHandler(
                StripeCardErrorMessage.StripeCardInvalidCCVNumber,
            );
            return;
        }

        if (cardNumberIsValid && cardExpiryIsValid && cardCvcIsValid) {
            setStripeCardInfoErrorHandler(StripeCardErrorMessage.IsValid);
        }
    }, [cardNumberIsValid, cardExpiryIsValid, cardCvcIsValid]);

    function updateCardInfo(event: StripeElementChangeEvent): void {
        if (event.elementType == 'cardNumber') {
            console.log('Change');
            setCardNumberIsValid(event.complete);
        }

        if (event.elementType == 'cardExpiry') {
            setCardExpiryIsValid(event.complete);
        }

        if (event.elementType == 'cardCvc') {
            setCardCvcIsValid(event.complete);
        }

        setStripeCardElement(cardNumberElement);
        setStripeObject(stripe);
    }

    const style = {
        base: {
            fontFamily:
                'SourceSans3-SemiBold, SourceSans3-Regular-Medium, sans-serif',
            fontSize: '17px',
        },
    };

    const cardNumberClasses = clsx({
        'card-details-input': true,
        cardNumberElement: true,
        inputError:
            (!cardNumberIsValid ||
                stripePaymentProcessingError ===
                StripeProcessingErrorMessage.CardError) &&
            showErrors,
    });

    const cardExpiryClasses = clsx({
        'card-details-input': true,
        cardExpiryElement: true,
        inputError: !cardExpiryIsValid && showErrors,
    });

    const cardCvcClasses = clsx({
        'card-details-input': true,
        cardCvcElement: true,
        inputError: !cardCvcIsValid && showErrors,
    });

    async function submitPaymentUpdate(e) {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (loading) {
            return;
        }

        stripe
            ?.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
            })
            .then(async function(result) {
                if (result.error) {
                    // Show error to your customer
                    console.log('createPaymentMethod error: ', result.error.message);
                    setLoading(false);
                    setError(result?.error?.message ?? '');
                } else {
                    const paymentMethod = result.paymentMethod;
                    console.log('createPaymentMethod success 1: ', paymentMethod);
                    console.log(
                        'createPaymentMethod success 2: ',
                        purchaseRequestEndpointResponse,
                    );
                    await SubmitPaymentMethodUpdate(
                        { ...purchaseRequestEndpointResponse },
                        paymentMethod.id)
                        .then((result: any) => {
                            console.log('SubmitPaymentMethodUpdate success', result);
                            if (result?.success === true) {
                                if (result.value.status === 'ActionRequired') {
                                    // const newUrl =
                                    //     window.location.protocol +
                                    //     '//' +
                                    //     window.location.host +
                                    //     window.location.pathname;
                                    // window.location.replace(newUrl);
                                    setShowQRCode(`${process.env.REACT_APP_BASE_URL}/url/${result.shortUrlCode}`);
                                } else {
                                    // setUpdateModal(true);
                                    setPaymentAction('UpdatingPaymentDetails');
                                }
                            }

                            if (result.error) {
                                setError(result.error);
                            }
                        })
                        .catch((err) => {
                            console.log('SubmitPaymentMethodUpdate error', err);
                            setError(err.toString());
                        }).finally(() => {
                        });
                    console.log('result success:', result);
                }
            })
            .finally(() => {
                console.log('paymentMethod Final');
                setLoading(false);
            });
    }

    console.log('stripePaymentProcessingError', error);

    return (
        <>
            {loading ? <div className='update-card-lock-click' onClick={e => e.stopPropagation()} /> : null}
            <div className={'payment-details-container payment-details-container-update-card'}>
            <span className='update-card-text'>
                Update card details
            </span>
                <div className='payment-details-wrapper'>
                    <div className='payment-details-column-wrapper'>
                        {/*<input type='text' placeholder={'Card holder name'} className='payment-details-input' />*/}

                        <div className='card-details-wrapper'>
                            <CardNumberElement
                                className={cardNumberClasses}
                                options={{
                                    placeholder: 'Card number',
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />
                            {!cardNumberIsValid && showErrors && (
                                <span className={'inputErrorLabel'}>
                                Card-number is invalid
                            </span>
                            )}
                            {stripePaymentProcessingError ===
                                StripeProcessingErrorMessage.CardError &&
                                showErrors && (
                                    <span className={'inputErrorLabel'}>
                                    This card has been declined
                                </span>
                                )}
                            <CardExpiryElement
                                className={cardExpiryClasses}
                                options={{
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />
                            {!cardExpiryIsValid && showErrors && (
                                <span className={'inputErrorLabel'}>
                                Expiration-Date on card is invalid
                            </span>
                            )}

                            <CardCvcElement
                                className={cardCvcClasses}
                                options={{
                                    placeholder: 'CVC',
                                    style: style,
                                }}
                                onChange={(event): void => {
                                    // When the card number is filled and correct, add to atom
                                    updateCardInfo(event);
                                }}
                            />

                        </div>

                        {error && typeof error === 'string' && (
                            <span className='nintendo-error'>{error}</span>
                        )}

                        <button
                            onClick={submitPaymentUpdate}
                            className={clsx(
                                'subscribe-button',
                                loading && 'subscribe-button-loading',
                            )}
                        >
                            {loading ? 'Processing' : 'Update'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
