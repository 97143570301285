import React, { ReactElement } from 'react';
import './payment-methods.scss';
import { ReactComponent as ForwardIcon } from '../assets/BackArrow_InkyPen.svg';
import { ReactComponent as CardIcon } from '../assets/PaymentCard_InkyPen.svg';


interface PaymentMethodsProps {
    setActive: React.Dispatch<React.SetStateAction<number>>;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({ setActive }): ReactElement => {
    return (
        <div className='payment-methods-container'>
            <div className='payment-methods-column'>

                <button className='payment-method' onClick={() => setActive(2)}>
                    <CardIcon className='card-icon' />
                    <span className='payment-method-text'>
                        Credit/Debit Card
                    </span>
                    <ForwardIcon className='forward-icon' />
                </button>

            </div>
        </div>
    );
};

export default PaymentMethods;