import React from 'react';
import './SubscriptionResponsible.scss';
import { ReactComponent as Pink } from '../assets/SmallBanner_Pink_Halftone_InkyPen.svg';
import Logo from '../assets/InkyPenLogo.png';

const SubscriptionResponsible = () => {
    function closeBrowser() {
        const protocol = window.location.protocol;
        const host = window.location.host;

        const newURL = `${protocol}//${host}/closebrowser`;

        window.location.replace(newURL);
    }

    return (
        <div className='subscription-responsible-container'>
            <Pink className='subscription-responsible-banner' />
            <img alt={'logo'} src={Logo} className='subscription-responsible-logo' />

            <div className='content-wrapper'>
                <h3 className='subscription-responsible-title'>Update Payment</h3>

                <p className='subscription-responsible-text'>
                    Update the payment details on the platform you started your subscription.
                </p>

                <p className='subscription-responsible-text-2'>
                    If you need assistance, please contact support@inky-pen.com.
                </p>


                <button className='subscription-responsible-button' onClick={closeBrowser}>
                    OK!
                </button>
            </div>
        </div>
    );
};

export default SubscriptionResponsible;
