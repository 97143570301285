import React, { ReactElement } from 'react';
import './modal.scss';
import { ReactComponent as Pink } from '../assets/SmallBanner_Pink_Halftone_InkyPen.svg';

interface ModalProps {
    returnToPayment: () => void;
    error?: boolean;
    actionType: string;
}

const Modal = ({ returnToPayment, error, actionType }: ModalProps): ReactElement => {
    function closeBrowser() {
        const protocol = window.location.protocol;
        const host = window.location.host;

        const newURL = `${protocol}//${host}/closebrowser`;

        window.location.replace(newURL);
    }

    if (error) {
        return (
            <div className='inky-switch-modal-container'>
                <div className='switch-modal'>
                    <div className='switch-modal-header-wrapper'>
                        <Pink className='switch-modal-background' />
                        <div className='switch-modal-background-mobile' />
                        <header className='switch-header'>
                            Payment failed
                        </header>
                    </div>

                    <span className='switch-message switch-message-margin-both'>
                        {'Something went wrong when trying to process your payment.'}
                    </span>

                    <button className='switch-return-payment-button-wrapper' onClick={returnToPayment}>
                        {'Return To Payment'}
                    </button>

                    <button className='switch-close-button-wrapper' onClick={closeBrowser}>
                        <div className='nintendo-close-wrapper'>
                            <span>
                                {'Close'}
                            </span>
                            <div className={'nintendo-text-border'} />
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    if (actionType === 'InitialSubscription') {
        return (
            <div className='inky-switch-modal-container'>
                <div className='switch-modal'>
                    <div className='switch-modal-header-wrapper'>
                        <Pink className='switch-modal-background' />
                        <div className='switch-modal-background-mobile' />
                        <header className='switch-header'>
                            {'You\'re subscribed to InkyPen!'}
                        </header>
                    </div>

                    <span className='switch-message'>
                        {'Now you\'re ready to jump in and start reading!'}
                    </span>

                    <span className='switch-message switch-second-message'>
                        {'To manage your subscription, go to your account page.'}
                    </span>

                    <button className='switch-button' onClick={closeBrowser}>
                        {'Ok!'}
                    </button>
                </div>
            </div>
        );
    }


    return (
        <div className='inky-switch-modal-container'>
            <div className='switch-modal'>
                <div className='switch-modal-header-wrapper'>
                    <Pink className='switch-modal-background' />
                    <div className='switch-modal-background-mobile' />
                    <header className='switch-header'>
                        {'Payment Details updated!'}
                    </header>
                </div>

                <span className='switch-message switch-second-message'>
                    {actionType === 'UpdatingPaymentDetails' ? (<>Your payment details have been successfully
                        updated.</>) : null}
                    {/*{actionType === 'UpdatingPaymentDetailsForPastDue' ? (<>You can now read your comics*/}
                    {/*    again</>) : null}*/}
                </span>

                <button className='switch-button' onClick={closeBrowser}>
                    {'Ok!'}
                </button>
            </div>
        </div>
    );

};

export default Modal;
