import React, { ReactElement } from 'react';
import './UpdateHeader.scss';
import { ReactComponent as BackIcon } from '../assets/BackArrow_InkyPen.svg';
import { ReactComponent as CrossIcon } from '../assets/Cross_InkyPen.svg';
import Logo from '../assets/InkyPenLogo.png';

const UpdateHeader = ({ setActive, active, lastActive, setLastActive }): ReactElement => {
    function onCrossClicked() {
        if (active === 3) {
            setActive(lastActive);
            setLastActive(-1);
        } else {
            // window.close();

            const protocol = window.location.protocol;
            const host = window.location.host;

            const newURL = `${protocol}//${host}/closebrowser`;

            window.location.replace(newURL);
        }
    }

    function onBackClicked() {
        if (active === 3) {
            setActive(lastActive);
            setLastActive(-1);
        } else if (active > 0) {
            setActive(active => active - 1);
        }
    }

    return (
        <div className='update-header-container'>

            {active === 0 ? <div></div> : <button className='back-wrapper' onClick={onBackClicked}>
                <BackIcon className='back-icon' />
                <span className='back-text'>
                    Back
                </span>
            </button>}

            <img src={Logo} alt={'inkypen logo'} className='inkypen-logo' />

            <button className='cross-wrapper' onClick={onCrossClicked} tabIndex={11}>
                <CrossIcon className='cross-icon' />
            </button>

        </div>
    );
};

export default UpdateHeader;