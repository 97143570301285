import React from 'react';
import './coupon.scss';
import { useAtom } from 'jotai/index';
import { ActivePlan } from '../../store/PlansStore';
import { useAtomValue } from 'jotai';
import { couponAtom } from '../stores';
import { ReactComponent as Cross } from '../assets/Cross_InkyPen.svg';

const Coupon = ({ active, setActive, setLastActive }) => {
    const [activePlan, setActivePlan] = useAtom(ActivePlan);
    const [couponAtomValue, setCouponAtomValue] = useAtom(couponAtom);

    console.log('activePlan', activePlan);

    function onRedeemClicked() {
        setLastActive(active);
        setActive(3);
    }

    return (
        <div className='coupon-container' onClick={e => e.stopPropagation()}>

            <div className='coupon-wrapper'>
                <div className='enter-coupon-wrapper'>
                    {!couponAtomValue ?
                        <>
                            <span className='have-coupon-text'>
                                Have a coupon?
                            </span>

                            <button className='have-coupon-button' tabIndex={5} onClick={onRedeemClicked}>
                                Redeem Code
                            </button>
                        </>
                        :
                        <>
                            <span className=''>
                                Coupon applied!
                            </span>

                            <div className='coupon-applied-wrapper'>
                                <span className='coupon-applied-text'>
                                    {couponAtomValue}
                                </span>

                                <div onClick={() => setCouponAtomValue('')} className='coupon-applied-button'>
                                    <Cross className='coupon-applied-icon' />
                                </div>
                            </div>
                        </>
                    }
                </div>

                {activePlan ? <div className='price-wrapper'>

                    <div className='price-row'>
                        <span className='price-row-title'>
                            {activePlan.displayName}
                        </span>

                        <div className='price-value-wrapper'>
                             <span className='price-value-number'>
                                {activePlan.displayFee}
                             </span>
                            <span className='price-value-limit'>
                                /{activePlan.period} {activePlan.periodType}
                             </span>
                        </div>

                    </div>

                    {couponAtomValue ? <div className='price-row'>
                        <span className='price-row-title'>
                            Coupon: {couponAtomValue}
                        </span>

                        <div className='price-value-wrapper'>
                             <span className='price-value-number'>
                                -{activePlan.fee}
                             </span>
                            <span className='price-value-limit'>
                                /{activePlan.period} {activePlan.periodType}
                             </span>
                        </div>

                    </div> : null}

                    <div className='price-row-total'>
                        <span className='price-row-title'>
                            Total
                        </span>

                        <div className='price-value-wrapper'>
                             <span className='price-value-number'>
                                {activePlan.displayFee}
                             </span>
                            <span className='price-value-limit'>
                                 / {activePlan.period} {activePlan.periodType}
                            </span>
                        </div>

                    </div>

                </div> : null}
            </div>

        </div>
    );
};

export default Coupon;
