import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Header from './header/header';
import './SwitchPaymentApp.scss';
import './index.scss';
import Slider from './slider/slider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Coupon from './coupon/coupon';
import Modal from './modal/modal';
import {
    paymentActionAtom,
    PaymentStatus,
    paymentStatusAtom,
    showQRCodeAtom,
    startPollingAtom,
    startUpdatePollingAtom,
} from './stores';
import { useAtom, useAtomValue } from 'jotai';
import { UpdateCard } from './slider/UpdateCard';
import UpdateHeader from './UpdateHeader/UpdateHeader';
import SuccessPage from './SuccessPage/SuccessPage';
import Secure3D from './3DSecure/3DSecure';
import { getAccountInfo, PollingStatus, UpdatePollingStatus } from './endpoints/endpoints';
import SubscriptionResponsible from './SubscriptionResponsible/SubscriptionResponsible';
import { AccessTokenAtom, AccountInfoAtom } from '../store/AccountStore';
import { PlansAtom } from '../store/PlansStore';
import { useSetAtom } from 'jotai/index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Wrapper = (props: any) => (
    <Elements
        options={{
            fonts: [
                {
                    src: `url(${'../fonts/SourceSans3-SemiBold.ttf'})`,
                    family: 'SourceSans3',
                    style: 'normal',
                },
            ],
        }}
        stripe={stripePromise}
    >
        {props.children}
    </Elements>
);

export default function SwitchPaymentApp(): ReactElement {
    const [active, setActive] = useState(0);
    const [lastActive, setLastActive] = useState(0);
    const [paymentAtomValue, setPaymentStatus] = useAtom(paymentStatusAtom);
    const setStartPolling = useSetAtom(startPollingAtom);
    const [paymentAction, setPaymentAction] = useAtom(paymentActionAtom);
    // const CartId = useAtomValue(CartIdAtom);
    const [showQRCode, setShowQRCode] = useAtom(showQRCodeAtom);
    const [plans, _] = useAtom(PlansAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);
    const setAccessToken = useSetAtom(AccessTokenAtom);
    const startPolling = useAtomValue(startPollingAtom);
    const startUpdatePolling = useAtomValue(startUpdatePollingAtom);
    const update = useRef(new URLSearchParams(window.location.search).get('update') ?? false);
    const token = useRef(new URLSearchParams(window.location.search).get('token'));

    const [loading, setLoading] = useState('start');

    function callPaymentPolling() {
        PollingStatus().then(result => {
            console.log('callPaymentPolling:', result.response);
            const curStatus = result.response.status;

            if (paymentAction !== result.response.action) {
                setPaymentAction(result.response.action);
            }
            if (curStatus === 'Approved') {
                setShowQRCode('');
                setStartPolling(false);
                setPaymentStatus(PaymentStatus.Success);
                return;
            }

            if (curStatus === 'Rejected') {
                setShowQRCode('');
                setStartPolling(false);
                setPaymentStatus(PaymentStatus.Error);
                return;
            }

            console.log('PaymentStatus', paymentAtomValue, PaymentStatus.Waiting);
            if (paymentAtomValue === PaymentStatus.Waiting) {
                setTimeout(() => {
                    callPaymentPolling();
                }, 3000);
            }
        }).catch(() => {
            setTimeout(() => {
                callPaymentPolling();
            }, 3000);
        });
    }

    function callUpdatePaymentPolling() {
        UpdatePollingStatus().then(result => {
            console.log('callPaymentPolling', result.response);
            if (result.response === 'Approved') {
                setShowQRCode('');
                setPaymentStatus(PaymentStatus.Success);
                return;
            }

            console.log('PaymentStatus', paymentAtomValue, PaymentStatus.Waiting);
            if (paymentAtomValue === PaymentStatus.Waiting) {
                setTimeout(() => {
                    callUpdatePaymentPolling();
                }, 3000);
            }
        }).catch(() => {
            setTimeout(() => {
                callUpdatePaymentPolling();
            }, 3000);
        });
    }

    function returnToPayment() {
        setActive(2);
        setPaymentStatus(PaymentStatus.Waiting);
        setPaymentAction('');
    }

    useEffect(() => {
        if (startPolling) {
            console.log('start Polling', startPolling);
            callPaymentPolling();
            return;
        }

        if (startUpdatePolling) {
            console.log('start Update Polling', startPolling);
            callUpdatePaymentPolling();
        }
    }, [startPolling, startUpdatePolling]);

    useEffect(() => {
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        const token = urlParams.get('token');
        if (token) {
            console.log('token is', token);
            //localStorage.setItem('AccessToken', token);
            setAccessToken(token).then(() => {
                const newUrl =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    window.location.pathname;

                window.history.replaceState({}, document.title, newUrl);
            });
        }
    }, []);

    useEffect(() => {
        if (showQRCode === '' && new URLSearchParams(window.location.search).get('update')) {

        }
    }, [showQRCode, window.location.search]);

    useEffect(() => {
        if (showQRCode !== '') {
            setActive(4);
            setLastActive(0);
        }
    }, [showQRCode]);

    useEffect(() => {
        if (showQRCode === '' && update.current === 'true') {
            console.log('sasas', update.current);
            console.log(update.current);
            getAccountInfo(token.current ?? '').then(response => {
                console.log('response', response?.subscription?.billingResponsible);
                setLoading(response?.subscription?.billingResponsible);
                if (response?.subscription?.billingResponsible !== 'Nintendo') {
                    setLoading('Other');
                    console.log('sasas', 'Other');
                } else if (response?.subscription?.billingResponsible === 'Nintendo') {
                    setLoading('Nintendo');
                    console.log('sasas', 'Nintendo');
                }
            }).catch(() => {
                setLoading('4');
            });
        } else {
            setLoading('3');
        }
    }, []);

    if (showQRCode !== '') {
        return <>
            <Header
                setActive={setActive}
                active={active}
                lastActive={lastActive}
                setLastActive={setLastActive}
            />
            <Secure3D />
        </>;
    }

    if (paymentAtomValue === PaymentStatus.Success || paymentAtomValue === PaymentStatus.Error || paymentAction === 'UpdatingPaymentDetails') {
        // if (true) {
        // console.log(`Success! action: ${paymentAction}, Status: ${paymentAtomValue}`);
        return (
            <div className='switch-payment-container'>
                <Modal returnToPayment={returnToPayment} error={paymentAtomValue === PaymentStatus.Error}
                       actionType={paymentAction} />
                {/*<Modal returnToPayment={returnToPayment} error={true} actionType={paymentAction} />*/}
            </div>
        );
    }

    if (window.location.pathname === '/success') {
        return <Wrapper>
            <SuccessPage />
        </Wrapper>;
    }

    if (loading === 'Other' && update.current === 'true') {
        return <SubscriptionResponsible />;
    }

    if (loading === 'Nintendo' && update.current === 'true') {
        return (
            <div className='update-payment-header-content-wrapper'>
                <UpdateHeader
                    setActive={setActive}
                    active={active}
                    lastActive={lastActive}
                    setLastActive={setLastActive}
                />
                <div className='switch-payment-container-update'>
                    <Wrapper>
                        <UpdateCard />
                    </Wrapper>
                </div>
            </div>
        );
    }

    if (loading === 'start') {
        return <></>;
    }

    console.log('plans 33', plans);
    // Payment approved.
    return (
        <div className='switch-payment-container'>
            <Header
                setActive={setActive}
                active={active}
                lastActive={lastActive}
                setLastActive={setLastActive}
            />
            <Wrapper>
                <Slider
                    setActive={setActive}
                    active={active}
                    lastActive={lastActive}
                />
            </Wrapper>
            {plans.length > 0 && active !== 3 && (
                <Coupon
                    setActive={setActive}
                    active={active}
                    setLastActive={setLastActive}
                />
            )}
        </div>
    );

}
