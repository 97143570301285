import { DashCreator, Publishable, RestrictedTerritory } from './Dashboard';
import update from 'immutability-helper';
import { Thumbnail } from './Thumbnail';
import { NameTuple } from './FilterTuple';
import { DashProductVariant, DashProductVariantDTO, PriceInfo } from './Dashboard/DashProductVariant';
import { CannotPublishItem, WithPublishConditions } from './CannotPublishItem';
import dayjs from 'dayjs';
import { AssetLinkGroup, AssetLinkGroupDTO } from './AssetLinkGroup';
import { DashExternalLink } from './DashboardSeries';
import { DashReadableComp } from './DashReadableComp';
import Log from '../Kodansha/Services/Logger';

export class ProductCreator {
  creator: DashCreator;
  type: number;
  hidden: boolean;
  ordinal: number;
}

class ProductSeries {
  id: number;
  title: string;
  metaData: ProductSeriesMetaData;
  completionStatus: string;
}

class ProductSeriesMetaData {
  maxAgeRating: string;
}

class DashPriceableComp implements PriceInfo{
  price: number;
  priceTierId: number;
  rrp: number;
  type: string;
}

class DashGeoRestrictableComp {
  restrictedTerritories: RestrictedTerritory[] = [];
}

class DashAgeRateableComp {
  ageRating: string;
}


class DashBundleComp {
  items: DashBundleProduct[] = [];
}

class DashBundleProduct {
  id: number;
  productId: number;
  productName: string;
  price: number;
}

export class DashDownloadableComp {
  ingestCompleted: boolean;
  masterRecord: string;
  reverseReadOrder:boolean

  static hasChanged(a: DashDownloadableComp, b: DashDownloadableComp): boolean {
    if( a == null || b == null) return false;
    return (a.reverseReadOrder !== b.reverseReadOrder
    );
  }
}


//ToDO: Wrap getCannotPublishReasons in an interface.
/**
 * @deprecated Use DashProduct instead.
 */
class DashboardProduct extends Publishable implements WithPublishConditions {

  //values form DB.
  name: string;
  displayName: string = "";
  description: string;
  metaDescription?: string;
  category: string;
  categoryId: number;
  subCategory: string;
  subCategoryId: number;
  stockIsFinite: boolean;
  creators: ProductCreator[] =[];

  readable: DashReadableComp;
  priceable: DashPriceableComp;
  geoRestrictable: DashGeoRestrictableComp;
  ageRestrictable: DashAgeRateableComp;
  downloadable: DashDownloadableComp;
  bundle: DashBundleComp;

  defaultThumbnail: Thumbnail;
  thumbnails: Thumbnail[] = [];

  publishToApple: boolean;
  publishToAndroid: boolean;
  publishToWeb: boolean;
  ipTags: NameTuple[];
  trim:string;
  variants: DashProductVariant[];

  publishDate?: Date;

  stockCount: number;
  readableUrl: string;
  assetLinkGroups: AssetLinkGroup[];
  externalLinks: DashExternalLink[]=[];

  chapters: string;

  unlisted: boolean;

  publisher: ProductPublisher;

  canPublish(): boolean {
    if(!this.variants && this.variants.length <= 0) {
      return false;
    }
    for(let i=0; i <this.variants.length; i++) {
      if(!this.canPublishVariant(this.variants[i])) {
        return false;
      }
    }
    const urlIsNum = /^\d+$/.test(this.readableUrl);
    if(urlIsNum == true) return false;

    //ToDO: Check for ingest...
    return (this.name && this.name.length >0
      && (this.subCategory === "Chapter" || this.description && this.description.length > 0)
      && this.category
      && this.subCategoryId >= 0
      // && (this.subCategory === "Chapter" || this.thumbnails.length > 0)
      && (this.subCategory === "Chapter" || this.hasIngested())
    );
  }

  canPublishVariant(variant: DashProductVariant): boolean {
    const si = variant.stockInfo;
    const pi = variant.pricingInfo;

    if (!variant.enabled) {
      return true;
    }

    if(si && si.delayExpected) {
      //Delay Expected, make sure that the date is set and is in the future
      if(!si.delayedUntilDate || dayjs(si.delayedUntilDate).day() < dayjs().day()) {
        return false;
      }

      //Message is mandatory.
      if(!si.delayMessage || si.delayMessage.length === 0) {
        return false;
      }
    }

    return (!si || (
       si.height && si.weight && si.width && si.length &&
        (si.inventoryId || process.env.REACT_APP_PUBLISH_WITHOUT_INFLOW) &&
       ( pi.usingDefaultPrice || pi.type !== "Paid" || (pi.price !== null && pi.price !== undefined))
    ));
  }



  //ToDO: Can we res-structure this as a Rules engine?
  // i.e. Missing fields list? basic validation fields, etc.
  getCannotPublishReasons(): CannotPublishItem[] {
    const missingItems: CannotPublishItem[] = [];

    const emptyFields = this.getProductEmptyFields();
    const urlIsNumber = /^\d+$/.test(this.readableUrl);
    if(urlIsNumber === true){
      missingItems.push({
        title: "readable URL must be text",
        description: "The URL field cannot be just a number. It needs to be text or a combination of text and numbers"
      });
    }
    if(emptyFields && emptyFields?.length > 0 ) {
      missingItems.push({
        title: "Missing Product Information",
        description: "All the following fields must be filled in before you can publish this product",
        subItems: emptyFields
      });
    }

    // if(this.subCategory !== "Chapter" && this.thumbnails?.length === 0) {
    //   missingItems.push({
    //     title: "No main product image uploaded"
    //   });
    // }

/*    if(!this.variants || this.variants.length === 0) {
      missingItems.push({
        title: "Need at least 1 digital/physical variant"
      });
    }*/
    else {
      this.variants.forEach(x => {
        const issues = this.getVariantPublishIssues(x);
        if(issues.length > 0) {
          missingItems.push({
            title: `Variation ${x.stockInfo?.description?? x.type} has missing data`,
            description: "Either disable this or fill in the missing fields",
            subItems: issues
          });
        }
      });
    }

    return missingItems;
  }

  getVariantPublishIssues(variant: DashProductVariant): CannotPublishItem[] {
    if(!variant.enabled) {
      //Disabled variants don't need to be correct.
      return [];
    }
    let issues = [];

    if(!variant.pricingInfo.usingDefaultPrice && variant.pricingInfo.type === "Paid" &&
        (variant.pricingInfo.price === null || variant.pricingInfo.price === undefined)) {
      issues.push({
        title: "Price (Either set or use the default)"
      });
    }

    switch (variant.type) {
      case "Digital":
        issues = issues.concat(this.getDigitalVariantPublishIssues(variant));
        break;
      case "Print":
        issues = issues.concat(this.getPhysicalVariantPublishIssues(variant));
    }

    return issues;
  }

  getDigitalVariantPublishIssues(variant: DashProductVariant): CannotPublishItem[] {
    //To DO: Add them when we think of them.
    const issues = [];
    if (this.subCategory !== "Chapter" && !this.hasIngested()){
      issues.push({title: "Comic has not been ingested"});
    }
    return issues;
  }

  getPhysicalVariantPublishIssues(variant: DashProductVariant): CannotPublishItem[] {

    const issues = [];
    const si = variant.stockInfo;

    //Dimensions...
    if(!si.weight) issues.push({ title: "weight"});
    if(!si.height) issues.push({ title: "height"});
    if(!si.length) issues.push({ title: "length"});
    if(!si.width) issues.push({ title: "width"});

    if(!si.inventoryId && !process.env.REACT_APP_PUBLISH_WITHOUT_INFLOW) {
      issues.push({title: "Inflow Inventory item"});
    }

    if(si.delayExpected) {
      if(!si.delayedUntilDate) {
        issues.push({title: "If delay expected, Delay Date must be set."});
      }
      else if(dayjs(si.delayedUntilDate).day() < dayjs().day() ){
        issues.push({title: "If delay expected, Delay Date must be a future date."});
      }

      if(!si.delayMessage || si.delayMessage.length == 0) {
        issues.push({title: "If delay expected, message must be set"});
      }
    }

    return issues;
  }


  private getProductEmptyFields(): CannotPublishItem [] {
    const missingItems = [];

    if(!this.name || this.name.length === 0) {
      missingItems.push({ title: "Product Title"});
    }

    if(this.subCategory !== "Chapter" && (!this.description || this.description.length === 0)) {
      missingItems.push({ title: "Description"});
    }

    if(!this.category) {
      missingItems.push({ title: "Product Type"});
    }

    if(!this.subCategory) {
      missingItems.push({ title: "Type"});
    }

    if(this.category==="Publication") {
      if(!this.readable.issueNumber || this.readable.issueNumber <= 0) {
        missingItems.push({ title: "Sorting (must be non-zero)"});
      }

      // if(!this.description || this.description.length === 0) {
      //   missingItems.push({ title: "Description"});
      // }
    }


    return missingItems;
  }

  getOriginal(): DashboardProduct {
    return this.original? this.original as DashboardProduct : null;
  }

  //ToDo: Can we make this a shallow copy? (with immutability helper, child objects are never mutated anyway.)
  // Yes we can: Use Lodash or Object.assign.
  discardAllChanges(): DashboardProduct {
    return Object.assign(new DashboardProduct(),this.original, {
      original: this.original
    });
  }

  changeCategoryTo(category: string ): DashboardProduct {
    this.category = category;
    let readable: DashReadableComp = null;
    let priceable: DashPriceableComp = null;
    let ageRestrictable: DashAgeRateableComp = null;
    let geoRestrictable: DashGeoRestrictableComp = null;
    let downloadable: DashDownloadableComp = null;

    Log.debug("++++++++++++ Change category to " + category);
    switch (category) {
      case "Publication":
        this.readable = this.readable?? new DashReadableComp();
        this.priceable = this.priceable?? new DashPriceableComp();
        this.ageRestrictable = this.ageRestrictable?? new DashAgeRateableComp();
        this.geoRestrictable = this.geoRestrictable?? new DashGeoRestrictableComp();
        this.downloadable = this.downloadable?? new DashDownloadableComp();
        break;
      case "Game":
        this.priceable = this.priceable? this.priceable: new DashPriceableComp();
        break;
      case "Merchandise":
        this.priceable = this.priceable? this.priceable: new DashPriceableComp();
        break;
    }

    return update(this as DashboardProduct, {
      readable: {$set: readable},
      priceable: {$set: priceable},
      ageRestrictable: {$set: ageRestrictable},
      geoRestrictable: {$set: geoRestrictable},
      downloadable: {$set: downloadable}
    });
  }

  initialize(): void {
    const orig = new DashboardProduct();
    orig.status=Publishable.STATUS_NEW;
    this.original = orig;
    this.externalLinks = [];
    this.copyFromOriginal();
    this.status = Publishable.STATUS_DRAFT;
    this.variants = [];
    this.unlisted = false;
    this.changeCategoryTo('Publication'); //For Kodansha: only supports publications
  }

  hasChanged(): boolean {
    if (!super.hasChanged()) {
      const orig = this.getOriginal();

      // Function to log differences between two objects
      const logObjectDiff = (fieldName: string, fieldThis: any, fieldOrig: any) => {
        if (fieldThis !== fieldOrig) {
          console.log(`Difference in ${fieldName}:`);
          console.log(`   this.${fieldName}:`, fieldThis);
          console.log(`   orig.${fieldName}:`, fieldOrig);
        }
      };

      const externalLinkCompareFunc = (a:DashExternalLink,b:DashExternalLink) =>a.name.localeCompare(b.name);

      // Log differences for each field
      logObjectDiff("name", this.name, orig.name);
      logObjectDiff("displayName", this.displayName, orig.displayName);
      logObjectDiff("description", this.description, orig.description);
      logObjectDiff("metaDescription", this.metaDescription, orig.metaDescription);
      logObjectDiff("readable", this.readable, orig.readable);
      logObjectDiff("category", this.category, orig.category);
      logObjectDiff("subCategoryId", this.subCategoryId, orig.subCategoryId);
      logObjectDiff("creators", this.creators, orig.creators);
      logObjectDiff("publishToApple", this.publishToApple, orig.publishToApple);
      logObjectDiff("publishToAndroid", this.publishToAndroid, orig.publishToAndroid);
      logObjectDiff("priceable", this.priceable, orig.priceable);
      logObjectDiff("publishToWeb", this.publishToWeb, orig.publishToWeb);
      logObjectDiff("geoRestrictable", this.geoRestrictable, orig.geoRestrictable);
      logObjectDiff("defaultThumbnail", this.defaultThumbnail, orig.defaultThumbnail);
      logObjectDiff("ipTags", this.ipTags, orig.ipTags);
      logObjectDiff("variants", this.variants, orig.variants);
      logObjectDiff("readableUrl", this.readableUrl, orig.readableUrl);
      logObjectDiff("assetLinkGroups", this.assetLinkGroups, orig.assetLinkGroups);
      logObjectDiff("ageRestrictable", this.ageRestrictable, orig.ageRestrictable);
      logObjectDiff("trim", this.trim, orig.trim);
      logObjectDiff("externalLinks", this.externalLinks.sort(externalLinkCompareFunc), orig.externalLinks.sort(externalLinkCompareFunc));
      logObjectDiff("downloadable", this.downloadable, orig.downloadable);
      logObjectDiff('unlisted', this.unlisted, orig.unlisted);

      return (orig.name !== this.name
          || orig.displayName !== this.displayName
          || orig.description !== this.description
          || orig.metaDescription !== this.metaDescription
          || orig.unlisted !== this.unlisted
          || (this.readable !== null && this.readable.hasChanged(orig.readable))
          //|| orig.copyright !== this.copyright
          || orig.category !== this.category
          || orig.subCategoryId !== this.subCategoryId
          || orig.subCategoryId !== this.subCategoryId
          || JSON.stringify(orig.creators) !== JSON.stringify(this.creators)
          || this.publishToApple !== orig.publishToApple
          || this.publishToAndroid !== orig.publishToAndroid
          || orig.priceable?.type !== this.priceable?.type
          || orig.priceable?.price !== this.priceable?.price
          || orig.priceable?.priceTierId !== this.priceable?.priceTierId
          || this.publishToWeb !== orig.publishToWeb
          || JSON.stringify(orig.geoRestrictable?.restrictedTerritories) !== JSON.stringify(this.geoRestrictable?.restrictedTerritories)

          || this.defaultThumbnail?.localPath !== orig.defaultThumbnail?.localPath
          || JSON.stringify(this.ipTags) !== JSON.stringify(orig.ipTags)
          || JSON.stringify(this.variants) !== JSON.stringify(orig.variants)
          || this.readableUrl !== orig.readableUrl
          || JSON.stringify(this.assetLinkGroups) !== JSON.stringify(orig.assetLinkGroups)

          || orig.ageRestrictable?.ageRating !== this.ageRestrictable?.ageRating
          || orig.trim !== this.trim
          || orig.externalLinks?.length !== this.externalLinks?.length
          || JSON.stringify(orig.externalLinks.sort(externalLinkCompareFunc)) !== JSON.stringify(this.externalLinks.sort(externalLinkCompareFunc))
          || DashDownloadableComp.hasChanged(this.downloadable, orig.downloadable)
      );
    }

    return true;
  }

  canSubmitToApple(): boolean {
    if (!this.hasChanged() ) {
      if (this.name && this.description && this.defaultThumbnail) {
        return true;

      }
    }
    return false;
  }

  private hasIngested(): boolean{
    if (this.category === "Publication"){
        const digitalVariant = this.variants.filter(x =>  x.type === "Digital" && x.enabled);

      if (digitalVariant.length > 0){
        if (this.downloadable && this.downloadable.ingestCompleted){
          return true;
        } else{
          return  false;
        }
      }
    }
    return true;
}

  cannotSubmitToAppleReasons(): string[] {
    const reasons: string[] = [];
    if(this.hasChanged()) {
      reasons.push("Must save changes before submitting.");
    }

    if(!this.name) {
      reasons.push("Must have a title.");
    }

    if(!this.description) {
      reasons.push("Must have a description.");
    }

    if(!this.defaultThumbnail) {
      reasons.push("Must have a Cover photo (please ingest).");
    }

    return reasons;
  }

  /**
   * Override: Only products are schedule-able.
   */
  isToSchedule(): boolean {
    if(
        this.status === Publishable.STATUS_SCHEDULED ||
        this.status === Publishable.STATUS_SCHEDULED_PREORDER ||
        this.status === Publishable.STATUS_SCHEDULED_COMING_SOON
    ) {
      return (this.original.status !== this.status || this.publishDate !== this.getOriginal().publishDate);
    }
    return false;
  }


  /***
   * Helpers for Object.assign
   */
  mutate(newProps: any): DashboardProduct {
    const x = this as DashboardProduct;
    return Object.assign(new DashboardProduct(), x, newProps);
  }


  toApi(): DashboardProductDTO {
    return Object.assign({}, this,
        {
          publishDate: this.publishDate?( this.publishDate).toISOString():null,
          variants: this.variants?.map(x => x.toApi())
        });
  }

  /**
   * Assign self to original as well.
   * @param dto
   */
  static createFromApi(dto: DashboardProductDTO): DashboardProduct{
    if (!dto) return null;
    const dp = Object.assign(new DashboardProduct(), dto,{
      creators: dto.creators?.map(x => update(x, { creator: { $set: DashCreator.createFrom(x.creator) } })).sort((a, b) => {
            if (a.ordinal !== b.ordinal) {
              return a.ordinal - b.ordinal;
            }
            return a.creator.getName().localeCompare(b.creator.getName());
          },
      ),
      assetLinkGroups: dto.assetLinkGroups?.map(value => AssetLinkGroup.createFromApi(value)),
      publishDate: dto.publishDate?dayjs(dto.publishDate+'Z').toDate():null,
      readable:(dto.readable!== null&& dto.readable!== undefined)? DashReadableComp.createFromApi(dto.readable):new DashReadableComp(),
      priceable: (dto.priceable!== null && dto.priceable!== undefined)? Object.assign(new DashPriceableComp(), dto.priceable):new DashPriceableComp(),
      variants: dto.variants?.map(x => DashProductVariant.createFromApi(x)),
      externalLinks: dto.externalLinks
    });

    return Object.assign(new DashboardProduct(),dp, {
      original: dp
    });
  }
}

export interface DashboardProductDTO {
  name: string;
  displayName: string;
  description: string;
  metaDescription?: string;
  category: string;
  categoryId: number;
  subCategory: string;
  subCategoryId: number;
  stockIsFinite: boolean;
  creators: ProductCreator[];

  readable: DashReadableComp;
  priceable: DashPriceableComp;
  geoRestrictable: DashGeoRestrictableComp;
  ageRestrictable: DashAgeRateableComp;
  downloadable: DashDownloadableComp;
  bundle: DashBundleComp;

  defaultThumbnail: Thumbnail;
  thumbnails: Thumbnail[];

  publishToApple: boolean;
  publishToAndroid: boolean;
  publishToWeb: boolean;
  ipTags: NameTuple[];
  variants: DashProductVariantDTO[];

  publishDate?: string;

  stockCount: number;
  readableUrl: string;
  assetLinkGroups: AssetLinkGroupDTO[];
  externalLinks: DashExternalLink[];

  chapters: string;
}


type ProductPublisher ={
  id:number;
  name:string;
}

export {
  DashboardProduct, ProductSeries, DashBundleComp, DashPriceableComp, DashGeoRestrictableComp, DashAgeRateableComp
};
