import React, { ReactElement, useEffect } from 'react';
import './plans.scss';
import { useAtom, useAtomValue } from 'jotai/index';
import { ActivePlan, PlansAtom, SelectedPlanIdAtom } from '../../store/PlansStore';
import { Platform, CurrentPlatform } from '../../store/GenericAtoms';
// import Tier1 from '../assets/Subscribe_1Month_02_Web.png';
// import Tier2 from '../assets/Subscribe_6Month_02_Web.png';
// import Tier3 from '../assets/Subscribe_12Month_01_Web.png';
import clsx from 'clsx';
import { AddToCart, PurchaseRequest } from '../endpoints/endpoints';
import { CartIdAtom, PurchaseRequestEndpointResponse } from '../stores';

interface PlansProps {
    setActive: React.Dispatch<React.SetStateAction<number>>;
}

const Plans: React.FC<PlansProps> = ({ setActive }): ReactElement | null => {
    // const [error, setError] = useState(false);
    // const [coupon, setCoupon] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useAtom(SelectedPlanIdAtom);
    const [plans, fetchPlans] = useAtom(PlansAtom);
    const [activePlan, setActivePlan] = useAtom(ActivePlan);
    // const history = useHistory();
    const platform = CurrentPlatform;
    const [_, setPurchaseRequestEndpointResponse] = useAtom(PurchaseRequestEndpointResponse);
    const CartId = useAtomValue(CartIdAtom);

    useEffect(() => {
        fetchPlans();
    }, []);

    function InitTransaction(history: any) {
        //TODO: Handle this for each platform
        switch (platform) {
            case Platform.Web:
                break;
            case Platform.Steam:
                history.push('/steamtransaction');
                break;
        }
    }

    async function onTierClick(index: number) {
        setActive(1);
        // @ts-ignore
        setActivePlan(plans[index]);
        setSelectedPlanId(index + 1);
        if (typeof CartId === 'string') {
            const { productId, variantId, amount } = plans[index];
            console.log('variants', plans[index], { productId, variantId, amount });

            // let removeResult = true;
            // if (selectedPlanId !== -1) {
            //     removeResult = await removeFromCard(CartId, plans[index].productId);
            // }

            // console.log('await result', removeResult);

            // if (removeResult) {
            AddToCart(CartId, { productId, variantId, amount }).then((guestId: any) => {
                PurchaseRequest(guestId).then((r: any) => {
                    console.log('pays', r);
                    setPurchaseRequestEndpointResponse(r);
                });
            });
            // }

        } else {
            // Retrying if we didn't have cartId
            setTimeout(() => {
                onTierClick(selectedPlanId);
            }, 1000);
        }
    }

    // const Tiers = [Tier1, Tier2, Tier3];

    return (
        <div className={'subscription-modal-wrapper'} onClick={(e) => {
            e.stopPropagation();
        }}>

            <div className='plans-grid'>
                {plans.slice(0, 1).map((plan, index) => {
                    return <button key={index}
                                   tabIndex={index + 1}
                                   className={`plan ${selectedPlanId === index + 1 && 'plan-active'} ${clsx(selectedPlanId !== -1 && selectedPlanId !== index + 1 && 'plan-not-selected')}`}
                                   onClick={() => onTierClick(index)}>
                        <img alt={''}
                             src={'https://cdn.inky-pen.com/statics/InkyPen/Subscription/345421564-d76667df-7449-4be1-b4df-4541f0720033.png'}
                             className='plan-image' />
                        <span className={'plan-duration'}>
                            {plan.displayName}
                        </span>
                        <div className='plan-price-wrapper'>
                            <span className='plan-price'>{plan.displayFee}</span>
                            <span
                                className='plan-limit'>/{plan.period > 1 && plan.period + ' ' + plan.periodType}{plan.period === 1 && plan.periodType}</span>
                        </div>
                    </button>;
                })}

            </div>

        </div>
    );
};


export default Plans;