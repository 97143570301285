import React, {
    ChangeEvent,
    forwardRef,
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import './InkyPenEmail.scss';
import { isiOS } from '../../../utils/utils';
import { ReactComponent as Off } from '../../Assets/inky-icons/ShowToggle_OFF_InkyPen.svg';
import { ReactComponent as On } from '../../Assets/inky-icons/ShowToggle_ON_InkyPen.svg';

interface KodanshaEmailProps {
    placeHolder: string;
    errorMessage?: string;
    styles?: string;
    inputStyles?: string;
    onChange?: (...args) => void;
    value?: string;
    active?: boolean;
    type?: string;
    error?: boolean;
    name?: string;
    autocomplete?: string;
    onEnterPushed?: (...args) => void;
    instructions?: string[];
    withFocus?: boolean;
    ariaLabel?: string;
    label?: string;
    ariaLabeledBy?: string;
    onFocus?: (...args) => any;
    onBlur?: (...args) => any;
    onSubmit?: (...args) => any;
}

// eslint-disable-next-line react/display-name
const InkyPenEmail = forwardRef<HTMLInputElement, KodanshaEmailProps>(
    ({
         onChange,
         ariaLabel = '',
         label,
         onBlur,
         onFocus,
         ariaLabeledBy,
         placeHolder = '',
         name = '',
         errorMessage = '',
         error = false,
         withFocus = false,
         styles = '',
         autocomplete = '',
         inputStyles = '',
         value = '',
         active = true,
         type = 'text',
         instructions = [],
         onEnterPushed,
         onSubmit,
     }: KodanshaEmailProps, ref: MutableRefObject<HTMLInputElement>): React.ReactElement => {
        const [inputValue, setInputValue] = useState(value);
        const [showPassword, setShowPassword] = useState(false);
        const inputRef = useRef(null);
        const idRef = useRef(`${label}-input-${Math.random()}`);

        const onInputClicked = (e) => {
            e.stopPropagation();
        };

        useEffect(() => {
            if (ref?.current) {
                inputRef.current = ref?.current;
            }
            if (withFocus) {
                inputRef.current?.focus();
            }
        }, []);

        const onInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
            if (onChange) {
                onChange(e.target.value);
            }
        };

        useEffect(() => {
            setInputValue(value);
        }, [value]);

        const onKey = (e) => {
            e.key === 'Enter' && onEnterPushed && onEnterPushed(e);
        };

        const isError = useMemo(() => error || errorMessage !== '', [error, errorMessage]);

        return (
            <div onClick={onInputClicked} className={`inkypen-email-wrapper ${styles}`}>
                {/*{(label || placeHolder) && <label htmlFor={idRef.current} aria-label={'label for ' + (label ?? placeHolder)} style={{display: 'none'}}>{label}</label>}*/}

                <input className={`email-input ${inputStyles}`} placeholder={placeHolder}
                       onChange={onInputChanged}
                       onKeyDown={onKey}
                       aria-labelledby={ariaLabeledBy ?? ''}
                       id={idRef.current}
                       name={name}
                       onSubmit={onSubmit && onSubmit}
                       autoComplete={autocomplete}
                       onFocus={onFocus}
                       onBlur={onBlur}
                       ref={ref ?? inputRef}
                       aria-label={ariaLabel ?? placeHolder}
                       disabled={!active}
                       type={showPassword ? 'text' : type}
                       value={inputValue}
                       style={{
                           backgroundColor: active === false && '#d7d7d7',
                           border: active === false ? '2px solid #757575' : isError ? '2px solid red' : '2px solid #ababab',
                           color: active === false ? isiOS() ? '#757575' : '#757575' : isError && 'red',
                       }} />

                {type === 'password' ? showPassword ?
                    <Off className={'toggle-hide-password-icon'} onClick={() => setShowPassword(false)} /> :
                    <On className={'toggle-hide-password-icon'} onClick={() => setShowPassword(true)} /> : null}


                {errorMessage !== '' && <span className='error'>{errorMessage}</span>}

                {instructions !== undefined && instructions.map((message, index) => {
                    return (<span key={index} className={'error'}>{message}</span>);
                })}
            </div>
        );
    });

export default InkyPenEmail;
