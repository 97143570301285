import React, { ReactElement, useState } from 'react';
import './slider.scss';
import Plans from './plans';
import PaymentMethods from './payment-methods';
import clsx from 'clsx';
import PaymentDetails from './payment-details';
import AddCoupon from '../coupon/add-coupon';

const sliderText = [
    'Select a subscription plan',
    'Choose payment method',
    'Payment details',
];

interface SliderProps {
    active: number;
    lastActive: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
}

const Slider: React.FC<SliderProps> = ({ active, setActive, lastActive }): ReactElement => {

    return (
        <div className='slider-container'>

            <div className='slider-text-dots-wrapper'>
                {active !== 3 ? <div className='slider-dots-wrapper'>
                    {sliderText.map((dot, index) => {
                        return <span key={dot}
                                     className={`slider-dot ${clsx(active === index && 'slider-dot-active')}`} />;
                    })}
                </div> : null
                }
                <span className='slider-text'>
                    {active === 3 ? 'Redeem a coupon' : sliderText[active]}
                </span>
            </div>

            {active === 3 ?
                <AddCoupon setActive={setActive} lastActive={lastActive} />
                // : <div className='slider-wrapper' style={{ transform: `translateX(${-100 * active}vw)` }}>
                : <div className='slider-wrapper'>
                    {active === 0 && <Plans setActive={setActive} />}
                    {active === 1 && <PaymentMethods setActive={setActive} />}
                    {active === 2 && <PaymentDetails />}
                </div>}
        </div>
    );
};

export default Slider;