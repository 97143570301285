import React, { useState } from 'react';
import InkyPenEmail from '../../InkyPen/InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenArrow from '../../InkyPen/InkyPenUIKit/InkyPenArrow/InkyPenArrow';

import './add-coupon.scss';
import { claimCode } from '../endpoints/endpoints';
import { useSetAtom } from 'jotai';
import { couponAtom, paymentActionAtom, PaymentStatus, paymentStatusAtom } from '../stores';
import { useAtom } from 'jotai';

interface AddCouponProps {
    lastActive: number;
    setActive: React.Dispatch<React.SetStateAction<number>>;
}

const AddCoupon = ({ setActive, lastActive }: AddCouponProps) => {
    const [couponValue, setCouponValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const setPaymentStatus = useSetAtom(paymentStatusAtom);
    const [_, setPaymentAction] = useAtom(paymentActionAtom);

    function onClaimCode() {
        if (loading) {
            return;
        }

        setLoading(true);

        claimCode(couponValue).then((response) => {
            if (response.data.status.type === 'Success') {
                console.log('Coupon applied:', response.data);
                // setCouponAtom(couponValue);
                setPaymentStatus(PaymentStatus.Success);
                setPaymentAction('InitialSubscription');
                // setActive(lastActive);
            } else {
                setError(response.data.status.message);
                // setCouponAtom(couponValue);
                // setActive(lastActive);
                console.error('Error:', response.data.status.message);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function onInputChanged(value) {
        setCouponValue(value);
    }

    return (
        <div className='add-coupon-wrapper'>
            <span className='coupon-text'>Coupon code</span>

            <div className='enter-coupon-wrapper'>
                <InkyPenEmail value={couponValue} onEnterPushed={onClaimCode}
                              onChange={onInputChanged}
                              placeHolder={'Enter coupon code'}
                              error={false} />


                {error !== '' ? <div className='messages-wrapper'>

                    <span className='message'>
                        {error}
                    </span>

                </div> : null}

                <button className='enter-coupon-actions-wrapper' onClick={onClaimCode}>
                    <InkyPenArrow styles={'apply'} text={loading ? 'Loading' : 'Apply'} />
                </button>
            </div>

        </div>
    );
};

export default AddCoupon;
