import React, { ReactElement } from 'react';
import './payment-details.scss';
import { SwitchCardProcessingForm } from './SwitchCardProcessingForm';

const PaymentDetails = (): ReactElement => {
    return (
        <form className='payment-details-container'>
            <SwitchCardProcessingForm />
        </form>
    );
};

export default PaymentDetails;