import React, { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentStatus, paymentStatusAtom } from '../stores';
import { useSetAtom } from 'jotai/index';

const SuccessPage = () => {
    const stripe = useStripe();
    console.log('stripe', stripe);
    const setPaymentStatus = useSetAtom(paymentStatusAtom);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const clientSecret = urlParams.get('payment_intent_client_secret');

        if (stripe) {
            stripe.confirmCardPayment(clientSecret).then((result) => {
                console.log('Success page result', result);
                if (result?.paymentIntent?.status === 'succeeded') {
                    console.log('Success page error test', result);
                    setPaymentStatus(PaymentStatus.Success);
                    return;
                }

                if (result?.error) {
                    setPaymentStatus(PaymentStatus.Error);
                    return;
                }
            }).catch((error) => {
                console.log('confirmCardPayment error', error);
                // setError(true);
            });
        }
    }, [stripe]);

    return (
        <div>
            Loading
        </div>
    );
};

export default SuccessPage;
