import { atom } from 'jotai';
import apiClient from '../API/InkyAPI';
import { UserDownloadableData } from '../Models/UserDownloadableData';

export const ReadingProgressAtom = atom<UserDownloadableData[]>([]);

export const FetchReadingProgressAtom = atom((get) => {
        return get(ReadingProgressAtom);
    },
    async (get, set ) => {
        apiClient.getReadingProgress(null).then((response) => {
            set(ReadingProgressAtom, response.data.response);
        }).catch(err => {
            console.error('Something went wrong when fetching MyBooks', err);
        });
    },
);