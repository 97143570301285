import { atom, PrimitiveAtom } from 'jotai';
import { SubscriptionType } from '../SwitchPayment/constants';
import { Platform } from './GenericAtoms';
import { isElectron } from 'react-device-detect';
// This store needs to be written again as I didn't want to change kodansha codes and endpoint :)

export interface PlansType {
    tierId: number;
    period: number;
    periodType: string;
    fee: number;
    displayName: string;
    description: string;
    costPerPeriod: string;
}

export const fetchData = async (platform: Platform = null): Promise<PlansType[]> => {
    let apiUrl = process.env.REACT_APP_BASE_URL + '/subscriptiontier/all';

    console.log('Platform', platform);
    //Currently this should always be US when using Steam, might be different for web/Stripe?
    if (platform == Platform.Steam) {
        const steamId = await window?.electron?.getSteamId();
        apiUrl = apiUrl + `?billingResponsible=Steam&steamId=${steamId}`;
    }

    try {
        const response = await fetch(apiUrl);
        console.log('plans', response);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const PlansCache = atom<(PlansType & Partial<SubscriptionType>)[]>([]);
// export const ActivePlan = atom<PlansType & Partial<SubscriptionType> | undefined>(undefined);
export const ActivePlan = atom<PlansType & Partial<SubscriptionType> | undefined>(undefined) as PrimitiveAtom<PlansType & Partial<SubscriptionType> | undefined>;
export const PlansAtom = atom(
    (get) => get(PlansCache),
    async (get, set) => {
        console.log('PlansAtom here');
        const platform = isElectron ? Platform.Steam : Platform.Web;
        try {
            const result = await fetchData(platform);

            const mergedSubscriptionType = result;
            console.log('mergedSubscriptionType', mergedSubscriptionType);
            set(PlansCache, mergedSubscriptionType);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },
);

export const SelectedPlanIdAtom = atom(-1);
